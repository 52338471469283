<template>
  <div class="Msg">
    <el-alert v-if="errorMsg"
      :title="errorMsg"
      type="error"
      effect="dark">
    </el-alert>
    <div class="msgList" v-if="msgList.length>0">
      <el-card :class="'box-card ' + msg.cmd" v-for="(msg, index) in msgList" :key="index" shadow="always">
        <div slot="header" class="clearfix">
          <span v-if="msg.cmd=='notify'">境外付款</span>
          <span v-if="msg.cmd=='disburse'">境内代发</span>
        </div>
        <div class="content">
          <div class="row">
            <div class="label">流水号(ISV)：</div>
            <div class="value">{{msg.data.isv_payment_id}}</div>
          </div>
          <div class="row">
            <div class="label">学号：</div>
            <div class="value">{{msg.data.student_no}}</div>
          </div>
          <div class="row">
            <div class="label">金额：</div>
            <div class="value">￥{{util.moneyFen(msg.data.amount)}}元</div>
          </div>
          <div class="row">
            <div class="label">时间：</div>
            <div class="value">{{msg.data.atime}}</div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="noMsg" v-else>
      <h2>还没有消息</h2>
    </div>
    <div v-if="refresh"></div>
  </div>
</template>

<script>
import ws from '@/utils/wsclient'
export default {
  name: 'Msg',
  data() {
    return {
      refresh: false,
      errorMsg: '',
      msgList: [],
      client: null,
    }
  },
  created (opt) {
    this.client = ws.client;
    this.client.on('message', msg=>{
      //{"cmd":"disburse","data":{"student_no":"10000001","amount":"40000"}}
      this.msgList.unshift(msg)

    })
    this.msgList = this.global.msgList

    var cc = 20 - this.msgList.length
    if (cc > 0) {
      //取出最近的20笔消息
      this.loadData()
    }
  },
  methods: {
    async loadData(cc) {
      if (cc < 1) {
        cc = 20
      }
      this.post('api/pay/lastPay', {count: cc}, res=>{
        if (res.code != 0) {
          this.errorMsg = '获取最近的消息失败~' + res.msg
          return
        }
        this.msgList = this.msgList.concat(res.data)
        this.refresh = !this.refresh
        // console.log(this.msgList)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.Msg {
  margin-top: 0px;
}
.msgList{
  height: calc(100vh - 12em);
  overflow-y: auto;
  // border: 1px solid red;
  display: flex;
  flex-wrap: wrap;

  .notify {
    background: #edecf5;
  }
  .disburse {
    background-color: #e1f7e3;
  }
  .box-card{
    margin: 10px;
    width: 20em;
    text-align: left;

    .clearfix{
      font-weight: 700;
      height: 0.8em;
    }

    .content{
      display: flex;
      flex-direction: column;

      .row{
        display: flex;
        font-size: 1.2em;
        margin-top: 10px;

        .label {
          color: $primary
        }
      }
      .row:first-child{
        margin-top: 0px;
      }
    }
  }
}

.noMsg {
  color: #ccc;
}
</style>
